<template>
<!-- tab子组件 -->
<div class="hiddensol">
  <div class="tabtablelist">
      <div class="border" :key="item.CONTENT" v-for="item in listData">
        <el-button @click="action(item.CONTENT)" type="text">•{{item.TITLE}}</el-button>
        <span class="datetime">{{item.SEND_DT}}</span>
      </div>
  </div>
</div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'Mytodo',
  props: ['tabName'],
  data () {
    return {
      listData: []
    }
  },
  created () {
    const obj = {}
    obj.getType = this.tabName
    obj.rowNum = 50
    obj.pageNum = 1
    requestForm('/api/globle/getPsnAgenda', 'post', obj).then((res) => {
      if (res) {
        this.listData = res.data
      }
    })
  },
  methods: {
    action (data) {
      if (data) {
        location.href = data
      }
    }
  }
}
</script>
<style scoped lang="scss">
.border{
    // padding: 10px;
    height: 39px;
    font-size: 14px;
    color: #666666;
    border-bottom: 1px solid #EBEEF5;
    .datetime{
    float: right;
    padding: 10px 0px;
}
}
.border :hover{
color: #409EFF;
}
.tabtablelist{
    height: calc(100vh - 643px);
    overflow: auto;
    min-height: 340px;
    margin-right: -17px;
    padding-right: 17px
}
.hiddensol{
  width: 100%;
  overflow: hidden;
}
</style>
